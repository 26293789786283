import React from 'react';
import { reaction } from 'mobx';
import { useTeamsQuery } from 'src/entities/teams/queries/teamsQuery';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import {
  DropdownFieldModel,
  DROPDOWN_ALL_OPTION_ID,
  DROPDOWN_BLANK_VALUE,
  DropdownOption,
} from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { observer } from 'mobx-react-lite';
import { useCurrentUserRole } from 'src/context/authContext';
import { useI18nContext } from 'src/context/i18n';
import { Team } from 'src/entities/teams/domain';
import { InputFieldValidator } from 'src/shared/ui/inputs/InputFieldModel';

interface TeamPickerProps {
  className?: string;
  model: DropdownFieldModel;
  filter?: (team: Team) => boolean;
  withAllTeams?: boolean;
  disabled?: boolean;
  division?: string;
}

export const TeamPicker: React.FC<TeamPickerProps> = observer(
  ({ className, model, filter, withAllTeams, disabled, division }) => {
    const i18n = useI18nContext();
    const query = useTeamsQuery({ filters: { name: model.debouncedSearchValue, division } });
    const { isAdmin } = useCurrentUserRole();

    React.useEffect(() => {
      model.setIsLoading(query.isFetching);
    }, [model, query.isFetching]);

    React.useEffect(() => {
      if (query.data && Array.isArray(query.data.teams)) {
        let queryData = query.data.teams;
        if (filter) {
          queryData = query.data.teams.filter(filter);
        }

        const teams = queryData
          .map(team => ({
            id: String(team.id),
            label: isAdmin ? `${team.name} (${team.id})` : team.name,
          }))
          .sort((t1, t2) => {
            if (t1.label.toLowerCase() < t2.label.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        (isAdmin || withAllTeams) &&
          teams.unshift({ id: DROPDOWN_ALL_OPTION_ID, label: i18n.t('shared.fields.labels.allTeams') });
        model.setOptions(teams);

        if (!model.value.id && model.options.length > 0) model.setDefaultValue(model.options[0]);
        //if (teams.findIndex(({ id }) => id === model.defaultValue.id) < 0) model.setDefaultValue(teams[0]);
      }
    }, [query.data, model, isAdmin, i18n, filter, withAllTeams]);

    return <DropdownField className={className} model={model} disabled={disabled} isSmall />;
  },
);

export const teamCacheKey = 'SELECTED_TEAM';

function setTeamPickerValueFromCache(teamPicker: DropdownFieldModel): void {
  const cachedTeam = localStorage.getItem(teamCacheKey);

  if (cachedTeam) {
    teamPicker.setDefaultValue(JSON.parse(cachedTeam));
  }

  reaction(
    () => teamPicker.value,
    () => {
      localStorage.setItem(teamCacheKey, JSON.stringify(teamPicker.value));
    },
  );
}

interface CreateTeamPickerDropdownProps {
  props: RequireMinOne<Partial<DropdownFieldModel['props']>, 'i18n'>;
  useCache?: boolean;
  localSearchDisabled?: boolean;
  title?: string;
  id?: string;
}

export function createTeamPickerDropdown({
  props,
  localSearchDisabled,
  useCache,
  title,
  id,
}: CreateTeamPickerDropdownProps): DropdownFieldModel {
  const picker = new DropdownFieldModel({
    id: id || 'team',
    initialValue: { id: DROPDOWN_BLANK_VALUE.id, label: props.i18n.t('shared.phrases.loading') },
    title: title || props.i18n.t('shared.fields.titles.team'),
    localSearchDisabled,
    withSearchbox: true,
    isFirstOptionDefault: true,
    ...props,
  });

  if (useCache) {
    setTeamPickerValueFromCache(picker);
  }

  return picker;
}
